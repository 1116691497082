import { FaEnvelope, FaUser, FaCheckCircle, FaBan, FaTimes } from 'react-icons/fa'
import { ENV } from '../apikeys'
import emailjs from 'emailjs-com'
import { useRef, useState } from 'react'
import loading from '../assets/loading.gif'
import { useGlobalContext } from '../context'

const ContactForm = ({title, namePH, sendButton}) => {

    const { language } = useGlobalContext()
    const form = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState(null)
    const [showMessage, setShowMessage] = useState(true)
    const [success, setSuccess] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        
        let errorsArray = []
        setErrors(null)
        setSuccess(null)
        setShowMessage(true)
        setIsLoading(true)

        if(e.target.user_name.value === '' || e.target.user_email.value === '' || e.target.message.value === '') {
            errorsArray.push(language === 'en' ? {name: 'Empty field(s)', message:'Please fill in all the fields.'} :  {name: 'Champ(s) vide(s)', message:'Merci de remplir tous les champs.'})
        }

        if(e.target.user_email.value !== '' && e.target.user_email.value.indexOf('@') === -1) {
            errorsArray.push(language === 'en' ? {name: 'Invalid email', message:'Please enter a correct email.'} :  {name: 'Email invalide', message:'Merci d\'entrer un email correct.'})
        }

        if (errorsArray.length === 0){
            emailjs.sendForm(ENV.SERVICE_ID, ENV.TEMPLATE_ID, e.target, ENV.USER_ID)
                .then((result) => {
                    setSuccess(true)
                    setIsLoading(false)
                }, (error) => {
                errorsArray.push(language === 'en' ? {name: 'Service error', message:'Sorry for the inconvenience. Please try contacting me at lea.marie3@live.fr instead.'} : {name: 'Erreur du service', message:'Vous pouvez me contacter directement sur lea.marie@live.fr à la place.'})
                    setErrors(errorsArray)
                    setIsLoading(false)
                });
        }
        else {
            setErrors(errorsArray)
            setIsLoading(false)
        } 
    }

    const handleClick = () => {
        setShowMessage(false)
    } 

    return ( 
        <form ref={form} onSubmit={handleSubmit} >
            <h3>{title}</h3>
            <div className='form-control'>
                <FaUser /> 
                <input type='text' name="user_name" placeholder={namePH}/>
            </div>
            <div className='form-control'>
                <FaEnvelope />
                <input type='text' name="user_email" placeholder="e-mail"/>
            </div>
            <div className='form-control'>
                <textarea name="message" />
            </div>
            { isLoading && 
                <div className='message loading'>
                    <img src={loading} alt='Loading' />
                    <span>Loading...</span>
                </div>
            }
            {(success && showMessage ) &&
                <div className='message success'>
                    <FaCheckCircle className='message-icon' />
                    <div>
                        <p>{language === 'en' ? 'Email sent!' : 'Message envoyé !'}</p>
                        <span>{language === 'en' ? 'Thanks for contacting me.' : 'Merci de m\'avoir contactée'}</span>
                    </div>
                    <FaTimes onClick={handleClick} className='close-btn' />
                </div>
            }
            {(errors && showMessage) &&
                <div className='message error'>
                    <FaBan className='message-icon' />
                    <div>
                        <p>{errors[0].name}</p>
                        <span>{errors[0].message}</span>
                    </div>
                    <FaTimes onClick={handleClick} className='close-btn' />
                </div>
            }
            <button className='btn btn-primary' type='submit'>{sendButton}</button>
        </form>
    )
}

export default ContactForm