import BarChart from '../components/BarChart'
import ExperienceCard from '../components/ExperienceCard'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context'
import resumeEN from '../assets/leamarie-resume-english.pdf'
import resumeFR from '../assets/leamarie-resume-french.pdf'

const About = () => {

    const { data, language } = useGlobalContext()
    const {title, intro, contactButton, resumeButton, skillsTitle, experienceTitle, experiences, skills } = data.about

    const handleClick = () => {
        const resume = (language === 'en') ? resumeEN : resumeFR
        window.open(resume, '_blank')
    }
    return (
        <section className='about'>
            <h2>{title}</h2>
            <p className='page-intro'>{intro}</p>
            <div className='about-cta'>
                <button className='btn btn-secondary'><Link to='/contact'>{contactButton}</Link></button>
                <button onClick={handleClick} className='btn btn-primary'>{resumeButton}</button>
            </div>
           <div className='about-content'>
                <div className='skills'>
                    <h3>{skillsTitle}</h3>
                    {skills.map( el => {
                        return <BarChart key={el.name} value={el.value} name={el.name} desc={el.tags} />
                    })}
                </div>   
                <div className='experience'>
                    <h3>{experienceTitle}</h3>
                    {experiences.map(el => {
                        return <ExperienceCard key={el.name} name={el.name} place={el.place} date={el.date} desc={el.desc}/>
                    })}
                </div>
           </div>         
        </section>
    )
}

export default About