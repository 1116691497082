import WorkCard from "../components/WorkCard"
import { useState } from 'react'
import { useGlobalContext } from "../context"

const Work = () => {

    const { data, language } = useGlobalContext()

    const [works, setWorks] = useState(data.workList)

    const handleChange = (e) => {
        if(e.target.value === 'all'){
            setWorks(data.workList)
        } else {
            const newList = data.workList.filter(el => el.tags.indexOf(e.target.value) !== -1)
            setWorks(newList)
        }
    }

    const { title } = data.work
    
    return (
        <section className='work'>
            <h2>{title}</h2>
            <nav onChange={handleChange} className='filters'>
                <input type='radio' name='filters' id='all' value='all' defaultChecked />
                <label htmlFor='all'>{language === 'en' ? 'All' : 'Tout'}</label>
                <input type='radio' name='filters' id='react' value='react' />
                <label htmlFor='react'>React</label>
                <input type='radio' name='filters' id='three' value='three' />
                <label htmlFor='three'>Three</label>
                <input type='radio' name='filters' id='vanilla' value='vanilla' />
                <label htmlFor='vanilla'>Vanilla</label>
                <input type='radio' name='filters' id='noscript'     value='noscript' />
                <label htmlFor='noscript'>No Script</label>
            </nav>
            <section className='work-list'>
                {works.map( work => {
                    return <WorkCard key={work.id} work={work} />
                })}
            </section>  
        </section>
    )
}

export default Work