import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useGlobalContext } from '../context'

const WorkCard = ({work}) => {
    
    const { isMobile } = useGlobalContext()
    const [isHovered, setIsHovered] = useState( isMobile ? true : null)

    const styles = useSpring({ bottom: isHovered ? '0px' : '-75px' })

    return (
        <div onMouseEnter={ () => setIsHovered(true) } onMouseLeave= { () => setIsHovered(false)} className='work-card'>
            <Link to={`/work/${work.id}`} >
                <img src={`/img/${work.img_thumb}`} alt={work.title} />
                <animated.div style={styles} className='work-card-content'>
                    {work.tags.map( tag => {
                        return <span className='tag' key={tag}>#{tag}</span>
                    })}
                    <p>{work.title}</p>
                </animated.div>
            </Link>
        </div> 
    )
}

export default WorkCard