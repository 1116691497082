import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../context';

const BottomNav = ({pageId}) => {

    const { language, data } = useGlobalContext()

    let nextId = parseInt(pageId) + 1
    if (nextId > data.workList.length) nextId = 1

    let prevId = parseInt(pageId) - 1
    if (prevId < 1) prevId = data.workList.length
    
    return (
        <nav className='bottom-nav'>
            <Link to={`/work/${prevId}`}>
                <FaLongArrowAltLeft/>
                <span>{language === 'en' ? 'Previous' : 'Précédent'}</span>
            </Link>
            <Link to={`/work/${nextId}`}>
                <span>{language === 'en' ? 'Next' : 'Suivant'}</span>
                <FaLongArrowAltRight/>
            </Link>
        </nav>
    )
}

export default BottomNav