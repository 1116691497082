import React, { useContext, useState, useEffect } from 'react'
import en from './en.json'
import fr from './fr.json'

const AppContext = React.createContext()

const AppProvider = ({children}) => {

    const [ language, setLanguage ] = useState('en')
    const [ data, setData ] = useState(en)
    const [ isMobile, setIsMobile ] = useState()

    useEffect( () => {
        if (language === 'en') setData(en)
        if(language === 'fr') setData(fr)
    }, [language])
    
    useEffect( () => {
        const detectMobile = () => {
            if( navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
            ){
                setIsMobile(true)
            }
            else {
                setIsMobile(false)
            }
        }
        detectMobile ();
        window.addEventListener('resize', detectMobile)
    }, [])

    return (
        <AppContext.Provider value={{ language, setLanguage, data, isMobile }}>
            {children}
        </AppContext.Provider>
    )
}

const useGlobalContext = () => {
    return useContext(AppContext)
}

export { AppContext, AppProvider, useGlobalContext }