import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context'

const MobileMenu = ({onClick}) => {

    const { language } = useGlobalContext()

    return (
        <div className='mob menu-content'>
            <ul onClick={onClick}>
                <li><Link to='/'>{language === 'en' ? 'Home' : 'Accueil'}</Link></li>
                <li><Link to='/work'>{language === 'en' ? 'Work' : 'Travaux'}</Link></li>
                <li><Link to='/about'>{language === 'en' ? 'About' : 'à propos'}</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
            </ul>
        </div>
    )
}

export default MobileMenu