import { useGlobalContext } from "../context"

const LanguageSwitch = () => {
    const { setLanguage, language } = useGlobalContext()

    const handleChange = (e) => {
        setLanguage(e.target.value)
    }

    return ( 
        <div className='language-switch'>
            <input onChange={handleChange} checked={language === "en" && true} type='radio' name='language' value='en' id='en' />
            <label htmlFor='en'>en</label>
            <span>|</span>
            <input onChange={handleChange} checked={language === "fr" && true} type='radio' name='language' value='fr' id='fr' />
            <label htmlFor='fr'>fr</label>
        </div>
    )
}

export default LanguageSwitch