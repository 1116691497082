import { Canvas } from '@react-three/fiber'
import { Stars, PerspectiveCamera } from "@react-three/drei";
import { Suspense, useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGlobalContext } from '../context';
import Logo3D from './Logo3D'

const Scene = () => {
    
    const { isMobile } = useGlobalContext()
    const camera = useRef()
    const location = useLocation()
    const [show, setShow] = useState()
    const [camPos, setCamPos] = useState([])

    useEffect( () => {
        setShow(location.pathname === '/' ? true : false) 
    }, [location.pathname])

    const animateCanvas = (e) => {
        const width = document.body.clientWidth;
        const posX = e.clientX;
        const distanceFromMiddle =  posX - (width * 0.5)
        const percentage = (distanceFromMiddle * 100) / (width * 0.5)

        if (document.readyState === 'complete' && !isMobile ){
            camera.current.rotation.y = 0.0002 * percentage
        }
    }

    useEffect ( () => {
        const adjustCamera = () => {
            const width = window.innerWidth
            if(width > 800) setCamPos([0, 0, 0.85])
            if(width < 800 && width > 576) setCamPos([0, 0, 1])
            if(width < 576 && width > 460) setCamPos([0, 0, 1.2])
            if(width < 460 && width > 400) setCamPos([0, 0, 1.3])
            if(width < 400) setCamPos([0, 0, 1.5])
        }

        adjustCamera()
        window.addEventListener('resize', adjustCamera)
    }, [])

    return ( 
        <div onMouseMove={animateCanvas} className='scene'>
            <Canvas >
                <PerspectiveCamera ref={camera} makeDefault position={camPos} />
                <Stars
                    radius={100}
                    depth={50}
                    count={900} 
                    factor={2}
                    saturation={0}
                    fade={true} 
                />
                <Suspense fallback={null}>
                    <Logo3D show={show} cam={camPos} />
                </Suspense>                
                <pointLight  intensity={1} position={[0,0,-10]}    />
                <pointLight intensity={0.1} position={[0,0,10]}    />
            </Canvas>
        </div>
    )
}

export default Scene