import './App.css'
import { Routes, Route } from "react-router-dom"
import { useEffect }  from 'react'

//Pages imports
import TopNav from './components/TopNav'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Work from './pages/Work'
import SingleWork from './pages/SingleWork'
import Contact from './pages/Contact'
import About from './pages/About'
import Scene from './components/Scene'
import PageWrapper from './components/PageWrapper'


function App() {
  
  //Corrects 100vh problems on mobile
  useEffect ( () => {
    const appHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--fullHeight', `${vh}px`);
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }, [])

  return (
    <>
        <TopNav />
        <Scene />  
        <Home />
        <PageWrapper>
          <Routes>
            <Route path="/" element={<div />} />
            <Route path="work" element={<Work />} />
            <Route path="work/:id" element={<SingleWork />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes> 
        </PageWrapper>
      </>
  )
}

export default App;



