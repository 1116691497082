import { useParams, Navigate } from "react-router-dom"
import { useEffect } from 'react'
import BottomNav from "../components/BottomNav"
import { useGlobalContext } from "../context"

const SingleWork = () => {
    
    const { data, language } = useGlobalContext()
    const { id } = useParams();
    let singleWork;

    data.workList.map( el => {
        if (el.id === parseFloat(id)) return singleWork = el
        return ''
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    if(singleWork === undefined) {
        return <Navigate to='*' />
    }

    const { title, description, tags, mobile, img, features, code_link, live_link } = singleWork
    
    const { featuresTitle, compatibilityTitle, viewCodeButton, tryLiveButton } = data.singleWork
    return (     
        <>
            <section className='single-work'>
                <h2>{title}</h2>
                <p className='page-intro'>{description}</p>
                {tags.map( tag => {
                    return <span className='tag' key={tag}>#{tag}</span>
                })}
                <a href={live_link} target='_blank' rel='noreferrer'>
                    <img src={`/img/${img}`} alt={title} />
                </a>
                <section className='single-work-footer'>
                    <div className='single-work-features'>
                        {features.length > 0 && 
                            <>
                                <h3>{featuresTitle}</h3>
                                <ol>
                                    {features.map( feature => {
                                        return <li key={feature}>{feature}</li>
                                    })}
                                </ol>
                            </>
                        }   
    
                        <h3>{compatibilityTitle}</h3>
                        {mobile ? 
                            <p>{ language === 'en' ? 'This project is available on mobile devices.' : 'Ce projet existe au format mobile'}</p> 
                            : <p>{ language === 'en' ? 'Unfortunately, this project is NOT available on mobile screens yet.' : 'Malheureusement, ce projet n\'existe pas encore au format mobile'}</p>
                        }
                    </div>
                    <div className='single-work-cta'>
                        <button className='btn btn-secondary'>
                            <a href={code_link} target='_blank' rel='noreferrer'>
                                {viewCodeButton}
                            </a>
                         </button>
                        <button className='btn btn-primary'>
                            <a href={live_link} target='_blank' rel='noreferrer'>
                                {tryLiveButton}
                            </a>
                        </button>
                    </div>
                </section>
            </section>
            <BottomNav pageId={id}/>
        </>
    )
}

export default SingleWork