import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTransition, animated } from '@react-spring/web'
import { FaGripLines, FaTimes } from 'react-icons/fa'
import logo from '../assets/logo-white.svg'
import MobileMenu from './MobileMenu'
import LanguageSwitch from './LanguageSwitch'
import { useGlobalContext } from '../context'

const TopNav = () => {
    const [show, setShow] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const { language } = useGlobalContext()
    const location = useLocation()

    useEffect( () => {
        setShow(location.pathname === '/' ? false : true) 
    }, [location.pathname])

    useEffect ( () => {
        if(!show) setOpenMenu(false)
    }, [show])

    const slideFromTop = useTransition(show, {
        from : {opacity: 0, transform: 'translateY(-50px)'}, 
        enter : {opacity: 1, transform: 'translateY(0px)'},
        leave : {opacity: 0, transform: 'translateY(-50px)'},
        config : {
          duration: 1000,
        }
      })

    return (
        slideFromTop((style, item) => (
            item && 
            <>
                <animated.nav style={{backgroundColor: openMenu ? 'var(--darkBlue' : 'var(--transparentDarkBlue)',...style}} className='top-nav' >
                    <div className='container'>
                        <div className='top-nav-left'>
                            <Link to='/'>
                                <img src={logo} alt='home' />
                            </Link>
                            <LanguageSwitch />
                        </div>
                        <ul className='desktop-menu'>
                            <li><Link to='/'>{language === 'en' ? 'Home' : 'Accueil'}</Link></li>
                            <li><Link to='/work'>{language === 'en' ? 'Work' : 'Travaux'}</Link></li>
                            <li><Link to='/about'>{language === 'en' ? 'About' : 'A propos'}</Link></li>
                            <li><Link to='/contact'>Contact</Link></li>
                        </ul>
                        <div className='mob menu-icon' onClick={ () => setOpenMenu(!openMenu)}>
                            {!openMenu && <FaGripLines />}
                            {openMenu && <FaTimes />}
                        </div>
                        {openMenu && <MobileMenu onClick={ () => setOpenMenu(false)}/> }
                    </div>
                </animated.nav>
                
            </>
        ))
    )
}

export default TopNav