import { FaEnvelope, FaPhoneAlt, FaLinkedinIn, FaGithub, FaCodepen } from 'react-icons/fa'
import ContactForm from '../components/ContactForm'
import { useGlobalContext } from '../context'

const Contact = () => {

    const { data } = useGlobalContext()

    const { title, intro, writeMeTitle, namePlaceholder, sendButton, findMeTitle } = data.contact
    return ( 
        <section className='contact'>
            <h2>{title}</h2>
            <p className='page-intro'>{intro}</p>
            <div className='contact-content'>
                <ContactForm title={writeMeTitle} namePH={namePlaceholder}  sendButton={sendButton}/>
               <div className='contact-content-socials'>
                    <h3>{findMeTitle}</h3>
                    <ul>
                        <li><FaPhoneAlt />+33 (0)6 17 74 17 39</li>
                        <li><FaEnvelope />lea.marie3@live.fr</li>
                        <li>
                            <a href='https://www.linkedin.com/in/lea-marie-paris13/' target='_blank' rel='noreferrer'>
                                <FaLinkedinIn/>linkedin
                            </a>
                        </li>
                        <li>
                            <a href='https://github.com/gypsydypsy' target='_blank' rel='noreferrer'>
                                <FaGithub/>github
                            </a>
                        </li>
                        <li>
                            <a href='https://codepen.io/hardcore_surimi' target='_blank' rel='noreferrer'>
                                <FaCodepen/>codepen
                            </a>
                        </li>
                    </ul>
               </div>
            </div>
        </section>
    )
}

export default Contact