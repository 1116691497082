import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTransition, animated } from '@react-spring/web'
import { useGlobalContext } from '../context'
import LanguageSwitch from '../components/LanguageSwitch'

const Home = () => {

    const [show, setShow] = useState(false)
    const location = useLocation()
    const { data, language } = useGlobalContext()

    useEffect( () => {
        setShow(location.pathname === '/' ? true : false) 
    }, [location.pathname])

    const fade = useTransition(show, {
        from : {opacity: 0}, 
        enter : {opacity: 1},
        leave : {opacity: 0},
        config : {
          duration: 500,
        }
      })

    const { intro, nav } = data.home

    return (
        fade((style, item) => (
        item && 
            <animated.section style={style} className='home'>
                <LanguageSwitch />
                <div className='heading'>
                    <h1>Léa Marie</h1>
                    <p>{intro}</p>
                </div>
                <nav className='home-nav'>
                    <ul>
                        <li><Link to='/work'>{ language === 'en' ? "Work" : "Travaux"}</Link></li>
                        <li><Link to='/about'>{ language === 'en' ? "About" : "à propos"}</Link></li>
                        <li><Link to='/contact'>contact</Link></li>
                    </ul>
                </nav>
            </animated.section>
        ))
    )
}

export default Home