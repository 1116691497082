const ExperienceCard = ({name, place, date, desc}) => {
    return (
        <div className='exp-card'>  
            <h4>{name}</h4>
            <span>{place}</span>
            <span>|</span>
            <span>{date}</span>
            <p>{desc}</p>
        </div>
    )
}

export default ExperienceCard