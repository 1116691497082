import { useSpring, animated } from '@react-spring/web'
import { useState, useEffect } from 'react'

const BarChart = ({name, value, desc}) => {

    const [animation, setAnimation] = useState(false)

    useEffect( () => {
        window.addEventListener('scroll', () => {
            setAnimation(true)
        })
    }, [])

    const animatedStyle = useSpring({
        to: {
            width: animation ? `${value}%` : '0%',
        }, 
        config: {
            duration: 1000
        },
        delay: 500
    })

    return (
        <div className='bar-chart'>
            <label className='bar-label'>{name}</label>
            {desc.map(el => {
                return <span key={el} className='tag'>#{el}</span>
            })}
            <div className='bar-track'>
                <animated.div className='bar-cursor' style={{...animatedStyle}}/>
            </div>
        </div>
    )
}

export default BarChart