import React, { useState } from 'react'
import { useGLTF } from '@react-three/drei'
import { useLoader } from "@react-three/fiber"
import { useSpring, animated } from '@react-spring/three'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import logoModel from '../3Dmodels/logoModel.gltf'
import texture from '../3Dmodels/texture.jpg'
import rough from '../3Dmodels/rough.jpg'

const Logo3D = (props) => {

  const { nodes, materials } = useGLTF(logoModel)
  const [isInverted, setIsInverted] = useState(false)
  const [isSpinning, setIsSpinning] = useState(false)

  const SPIN_DURATION = 700
  const cameraZ = props.cam[2]

  const zoom = useSpring({
    to: { position: props.show ? [0, 0, 0] : [0, 0, cameraZ] }, 
    config: {
      duration: 1000
    }
  })  

  const animation = useSpring({
    from: {
      rotation: !isInverted ? [ Math.PI / 2, 0, -0.2 ] : [ Math.PI / 2, 0, -0.2 + Math.PI ] 
    },
    to : {
      rotation: isSpinning ? isInverted ? [ Math.PI / 2, 0, Math.PI] : [ Math.PI / 2, 0, 0] : isInverted ? [Math.PI / 2, 0, 0.2 + Math.PI] : [Math.PI / 2, 0, 0.2]
    },
    loop: {
      reverse:true
    }, 
    config: {
      duration: isSpinning ? SPIN_DURATION : 15000,
    }
  })

  return (
    <group {...props} dispose={null}>
      <animated.mesh 
        geometry={nodes.Curve001.geometry}
        material={materials.Material}
        rotation={animation.rotation}
        scale={[5.7, 7.96, 5.69]}
        position={zoom.position}   
        onClick={ () => {
          setIsInverted(!isInverted)
          setIsSpinning(true)
          setTimeout( () => setIsSpinning(false), SPIN_DURATION)
        }}
      >
        <meshStandardMaterial 
          attach="material" 
          map={useLoader(TextureLoader, texture)} 
          roughnessMap={useLoader(TextureLoader, rough)}
          roughness={0.3} 
          /* color='darkSalmon' */
          /* color='indianRed' */
          /* color='lightCoral' */
          /* color='lightSalmon' */
          /* color='lightSeaGreen' */
          /* color='mediumAquaMarine' */
          /* color='salmon' */
          color='lightBlue'
      
          metalness={1}
          />
      </animated.mesh>
    </group>
  )
}

useGLTF.preload(logoModel)

export default Logo3D
