import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context'

const NotFound = () => {

    const { data } = useGlobalContext()

    const { title, intro, homeButton } = data.notFound

    return (
        <section className='not-found'>  
            <h2>{title}</h2>
            <p className='page-intro'>{intro}</p>
            <button className='btn btn-primary'><Link to='/'>{homeButton}</Link></button>
        </section>
    )
}

export default NotFound