import { useTransition, animated } from "@react-spring/web" 
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const PageWrapper = ({children}) => {

    const [show, setShow] = useState()
    const location = useLocation()
  
    useEffect( () => {
        setShow(location.pathname === '/' ? false : true) 
    }, [location.pathname])
  
    const transition = useTransition(show, {
      from : {opacity: 0, transform: 'translateY(100vh)'}, 
      enter : { opacity: 1, transform: 'translateY(0vh)'},
      leave : {opacity: 0, transform:  'translateY(100vh)'}, 
      config : {
        duration: 1000,
      }
    })

    return ( 
        transition((style, item) => (
            item &&
            <animated.div className='page' style={style}>
                <div className='container'>
                    {children}
                </div>
            </animated.div>
        ))
    )
}

export default PageWrapper